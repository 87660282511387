import React from 'react';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux';

const initialState = {
  shouldHideUnnecessaryModals: -1,
  isHamburgerActive: false,
  activePath: false,
  isSmallWindow: false,
  windowWidth: null,
  visibleSubpanel: null,
  isBlogPageLoaded: false,
  isNewsletterVisible: false,
  followersCount: 0,
};

const reducer = (state, action) => {
  if (action.type === 'UPDATE') {
    return Object.assign({}, state, action.payload);
  }
  if (action.type === 'HIDE_MODALS') {
    return Object.assign({}, state, {
      shouldHideUnnecessaryModals: state.shouldHideUnnecessaryModals + 1,
    });
  }
  return state;
};

export const actions = {
  hideUnnecessaryModals: () => ({
    type: 'HIDE_MODALS',
  }),
  setIsHamburgerActive: (payload) => ({
    type: 'UPDATE',
    payload: { isHamburgerActive: payload },
  }),
  setActivePath: (payload) => ({
    type: 'UPDATE',
    payload: { activePath: payload },
  }),
  setWindowData: (windowWidth, isSmallWindow) => ({
    type: 'UPDATE',
    payload: { windowWidth, isSmallWindow },
  }),
  setVisibleSubpanel: (payload) => ({
    type: 'UPDATE',
    payload: { visibleSubpanel: payload },
  }),
  setIsBlogPageLoaded: (payload) => ({
    type: 'UPDATE',
    payload: { isBlogPageLoaded: payload },
  }),
  setIsNewsletterVisible: (payload) => ({
    type: 'UPDATE',
    payload: { isNewsletterVisible: payload },
  }),
  setFollowersCount: (payload) => ({
    type: 'UPDATE',
    payload: { followersCount: payload },
  }),
};

export const store = createStore(reducer, initialState);

// eslint-disable-next-line react/display-name
export default ({ element }) => <Provider store={ store }>{ element }</Provider>;
