import React from 'react';
import styles from './MenuPlaceholder.module.scss';

const MenuPlaceholder = () => (
  <div className={ styles.MenuPlaceholder } >
    <div className={ styles.logo } ></div>
    <div className={ styles.links } >
      <div className={ styles.link } ></div>
      <div className={ styles.link } ></div>
      <div className={ styles.link } ></div>
      <div className={ styles.link } ></div>
    </div>
    <div className={ styles.button } ></div>
  </div>
);

export default MenuPlaceholder;
