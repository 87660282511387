import { useLocation } from '@reach/router';
import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import { useCallback } from 'react';

const SOURCE_NAME = 'source';

const usePageSource = () => {
  const location = useLocation();
  const [ cookies, setCookie ] = useCookies([ SOURCE_NAME ]);

  const getSource = useCallback(() => {
    if (location.search) {
      const search = queryString.parse(location.search);
      search?.[ SOURCE_NAME ] && setCookie(SOURCE_NAME, search.source);
    }
  });

  return {
    source: cookies[ SOURCE_NAME ],
    getSource: getSource,
    pathname: location.pathname,
  };
};

export default usePageSource;
