import React from 'react';
import Layout from './src/components/Layout/Layout';
import wrapWithProvider from './src/utils/store';
import ThemeConfig from 'utils/ThemeConfig';

export const wrapPageElement = ({ element, props }) => {
  if (props.serverData?.components) {
    return (
      <ThemeConfig>
        <Layout { ...props } pageContext={ props.serverData }>
          { element }
        </Layout>
      </ThemeConfig>
    );
  } else if (props.pageContext.components) {
    return (
      <ThemeConfig>
        <Layout { ...props }>{ element }</Layout>
      </ThemeConfig>
    );
  } else {
    return element;
  }
};

export const wrapRootElement = wrapWithProvider;
