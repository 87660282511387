import React from 'react';
import { ConfigProvider as ANTDThemeProvider } from 'antd';
import { defaultTheme as ANTDTheme } from '@ant-design/compatible';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

const ThemeConfig = ({ children }) => {
  const MUITheme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Poppins, sans-serif',
      },
    },
  });

  return (
    <MUIThemeProvider theme={ MUITheme }>
      <ANTDThemeProvider theme={ ANTDTheme }>
        { children }
      </ANTDThemeProvider>
    </MUIThemeProvider>
  );
};

export default ThemeConfig;
