import React, { useContext } from 'react';
import loadable from '@loadable/component';
import { AnalyticsContext } from 'utils/AnalyticsProvider';

const CookiesPanel = loadable(() => import('./CookiesPanel'));

const CookiesPanelWrapper = ({ policy, location }) => {
  const { cookiesConsent } = useContext(AnalyticsContext);
  return !cookiesConsent ? (
    <CookiesPanel policy={ policy } location={ location } />
  ) : null;
};

export default CookiesPanelWrapper;
