/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import loadable from '@loadable/component';
import { CookiesProvider } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import AOS from 'aos';
import 'aos/dist/aos.css';

import config from 'utils/config';
import { actions } from 'utils/store';
import usePageSource from 'hooks/usePageSource';

import AppleFavicon from 'src/assets/favicon/apple-touch-icon.png';
import Chrome192 from 'src/assets/favicon/android-chrome-192x192.png';
import Chrome256 from 'src/assets/favicon/android-chrome-256x256.png';
import FaviconIco from 'src/assets/favicon/favicon.ico';
import LogoPNG from 'src/assets/favicon/logo500px.png';
import FaviconIco32 from 'src/assets/favicon/favicon-32x32.png';
import FaviconIco16 from 'src/assets/favicon/favicon-16x16.png';

import PipedriveWrapper from 'components/Layout/Pipedrive/Pipedrive';
import Navigation from './Navigation/Navigation';
import CookiesPanelWrapper from './CookiesPanel/CookiesPanelWrapper';
import NewsletterWrapper from './Newsletter/NewsletterWrapper';
import { AnalyticsProvider } from 'utils/AnalyticsProvider';

import styles from './Layout.module.scss';
import 'styles/styles.scss';

const Footer = loadable(() => import('./Footer/Footer'));

const OVERFLOW_HIDDEN_CLASS = 'overflowHidden';

const Layout = ({
  children,
  pageContext: {
    components: { navbar, footer, newsletter },
    data: {
      urlPath,
      metadata: {
        title ='Profil Software',
        description = 'Python Software House',
        image,
        allowRobots = true,
        canonical,
      } = {},
      schema = {},
      newsletter: pageNewsletterData,
    },
  },
  location,
}) => {
  const isHamburgerActive = useSelector((state) => state.isHamburgerActive);
  const activePath = useSelector((state) => state.activePath);

  const dispatch = useDispatch();
  const setWindowData = (width, isSmall) => dispatch(actions.setWindowData(width, isSmall));
  const hideUnnecessaryModals = () =>
    dispatch(actions.hideUnnecessaryModals());
  const setIsHamburgerActive = (value) =>
    dispatch(actions.setIsHamburgerActive(value));

  const { getSource } = usePageSource();

  useEffect(() => {
    getSource();
  }, []);

  useEffect(() => {
    AOS.init();

    document.querySelectorAll('img')
        .forEach((img) =>
          img.addEventListener('load', () =>
            AOS.refresh(),
          ),
        );
  }, [ AOS ]);

  useEffect(() => {
    if (isHamburgerActive) setIsHamburgerActive(false);
  }, [ location.pathname, activePath, location.hash ]);

  useEffect(() => {
    isHamburgerActive && hideUnnecessaryModals();
    handleBodyOverflow();
  }, [ isHamburgerActive ]);

  const getWindowSize = () => {
    const size = typeof window !== 'undefined' && (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth);
    setWindowData(size, size <= 1150 );
  };

  useEffect(()=> {
    let eventTimeout;
    const eventHandler = () => {
      typeof window !== 'undefined' && window.clearTimeout( eventTimeout );
      eventTimeout = setTimeout(function() {
        getWindowSize();
      }, 500);
    };
    typeof window !== 'undefined' && window.addEventListener('resize', eventHandler, false);
    getWindowSize();
    return () => {
      typeof window !== 'undefined' && window.removeEventListener('resize', eventHandler);
      clearTimeout(eventTimeout);
    };
  }, []);

  const handleBodyOverflow = () => {
    const bodyElement = window.document.querySelector('body');
    if (bodyElement) {
      if (isHamburgerActive) {
        !bodyElement.classList.contains(OVERFLOW_HIDDEN_CLASS) &&
          bodyElement.classList.add(OVERFLOW_HIDDEN_CLASS);
      } else {
        bodyElement.classList.contains(OVERFLOW_HIDDEN_CLASS) &&
          bodyElement.classList.remove(OVERFLOW_HIDDEN_CLASS);
      }
    }
  };

  return (
    <CookiesProvider>
      <AnalyticsProvider>
        <div className={ styles.container }>
          <Helmet>
            <html lang='en' />
            <link rel="apple-touch-icon" href={ AppleFavicon } />
            <link rel="mask-icon" href={ LogoPNG } />
            <link rel="shortcut icon" href={ AppleFavicon } />
            <link rel="icon" href={ FaviconIco } />
            <link rel="icon" type="image/png" sizes="32x32" href={ FaviconIco32 } />
            <link rel="icon" type="image/png" sizes="16x16" href={ FaviconIco16 } />
            <link rel="icon" type="image/png" sizes="192x192" href={ Chrome192 } />
            <link rel="icon" type="image/png" sizes="256x256" href={ Chrome256 } />
            <meta name="msapplication-TileImage" content={ LogoPNG } />

            <title>{ title }</title>
            <meta name="title" content={ title } />
            <meta name="description" content={ description } />

            <meta itemProp="name" content={ title } />
            <meta itemProp="description" content={ description } />

            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:url" content={ location?.href } />
            <meta property="og:title" content={ title } />
            <meta property="og:site_name" content={ title } />
            <meta property="og:description" content={ description } />

            { image?.src && <meta property="og:image" content={ image.src } /> }
            { image?.alt && <meta property="og:image:alt" content={ image.alt } /> }

            <meta property="twitter:card" content={ image ? 'summary_large_image' : 'summary' } />
            <meta property="twitter:url" content={ location?.href } />
            <meta name="twitter:title" content={ title } />
            <meta name="twitter:description" content={ description } />
            { image?.src && <meta property="twitter:image" content={ image.src } /> }

            { schema && (
              <script type="application/ld+json">
                { JSON.stringify(schema) }
              </script>
            ) }

            { config.allowAnalytics && (
              <meta
                name="google-site-verification"
                content="Yrn4e0bsnx05VkXyQEO9L8zhzeNcVd2dQ_fpJYrq3xg"
              />
            ) }
            { (urlPath || canonical) && (
              <link
                rel="canonical"
                href={ canonical || config.url.replace(/\/$/, '') + urlPath.replace(/^\/main\/?/, '/') }
              />
            ) }
            { (!config.allowAnalytics || !allowRobots) && (
              <meta name="robots" content="noindex,nofollow" />
            ) }

          </Helmet>

          <PipedriveWrapper/>

          <Navigation
            navbar={ navbar }
            location={ location }
          />
          <div className={ styles.content }>{ children }</div>
          <LazyLoadComponent>
            <Footer footer={ footer } />
          </LazyLoadComponent>

          { pageNewsletterData && (
            <NewsletterWrapper data={ { ...newsletter, ...pageNewsletterData } }/>
          ) }

          <CookiesPanelWrapper policy={ footer.policy } location={ location }/>
        </div>
      </AnalyticsProvider>
    </CookiesProvider>
  );
};

export default Layout;
