import React, { createContext, useEffect } from 'react';
import config from './config';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const [ cookies, setCookie ] = useCookies([ 'cookiesConsent' ]);

  // eslint-disable-next-line require-jsdoc, no-inner-declarations
  function gtag() {
    // eslint-disable-next-line prefer-rest-params, no-undef
    window.dataLayer.push(arguments);
  }

  const setGrantConsent = () => {
    gtag('consent', 'default', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
    });
  };

  const setDenyConsent = () => {
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
    });
  };

  const updateGrantConsent = () => {
    setCookie('cookiesConsent', true, {
      expires: dayjs().add(14, 'day').toDate(),
      path: '/',
    });

    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
    });
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (config.allowAnalytics && config.googleTagmanager) {
      const consentGiven = cookies?.cookiesConsent;
      if (consentGiven) {
        setGrantConsent();
      } else {
        setDenyConsent();
      }
      gtag('js', new Date());
      gtag('config', config.googleTagmanager);
    }
  }, []);

  return (
    <AnalyticsContext.Provider value={ { updateGrantConsent, cookiesConsent: cookies?.cookiesConsent } }>
      { children }
    </AnalyticsContext.Provider>
  );
};
