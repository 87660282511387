import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'utils/config';
import { useSelector } from 'react-redux';

const CHAT_CLASS = 'chatHidden';
const hasPipedrive = config.pipedriveCompany && config.pipedrivePlaybook;

const Pipedrive = () => {
  const isHamburgerActive = useSelector((state) => state.isHamburgerActive);

  useEffect(() => {
    handleChatDisplay();
  }, [ isHamburgerActive ]);

  useEffect(() => {
    initChat();
  }, []);

  const initChat = () => {
    window.pipedriveLeadboosterConfig = {
      base: 'leadbooster-chat.pipedrive.com',
      companyId: config.pipedriveCompany,
      playbookUuid: config.pipedrivePlaybook,
      version: 2,
    };
    (function() {
      const w = window;
      if (w.LeadBooster) {
        console.warn('LeadBooster already exists');
      } else {
        w.LeadBooster = {
          q: [],
          on: function(n, h) {
            this.q.push({ t: 'o', n: n, h: h });
          },
          trigger: function(n) {
            this.q.push({ t: 't', n: n });
          },
        };
      }
    });
  };

  const handleChatDisplay = () => {
    const chatElement = window.document.querySelector('#LeadboosterContainer');
    if (chatElement) {
      if (isHamburgerActive) {
        !chatElement.classList.contains(CHAT_CLASS) &&
          chatElement.classList.add(CHAT_CLASS);
      } else {
        chatElement.classList.contains(CHAT_CLASS) &&
          chatElement.classList.remove(CHAT_CLASS);
      }
    }
  };

  return (
    <Helmet>
      <script
        src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
        async
      ></script>
    </Helmet>
  );
};

const PipedriveWrapper = () => hasPipedrive ? <Pipedrive/> : null;

export default PipedriveWrapper;
