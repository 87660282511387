import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import MenuPlaceholder from './MenuPlaceholder/MenuPlaceholder';

const DesktopMenu = loadable(() => import('./DesktopMenu/DesktopMenu'));
const MobileMenu = loadable(() => import('./MobileMenu/MobileMenu'));

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'utils/store';

const Navigation = ({ navbar, location }) => {
  const dispatch = useDispatch();
  const isSmallWindow = useSelector((state) => state.isSmallWindow);
  const windowWidth = useSelector((state) => state.windowWidth);
  const visibleSubpanel = useSelector((state) => state.visibleSubpanel);
  const setVisibleSubpanel = (value) =>
    dispatch(actions.setVisibleSubpanel(value));
  const hideUnnecessaryModals = () =>
    dispatch(actions.hideUnnecessaryModals());

  useEffect(() => {
    visibleSubpanel && setVisibleSubpanel(null);
    hideUnnecessaryModals();
  }, [ windowWidth ]);

  useEffect(() => {
    visibleSubpanel && hideUnnecessaryModals();
  }, [ visibleSubpanel ]);

  return navbar ? isSmallWindow ? (
    <MobileMenu
      navbar={ navbar }
      location={ location }
    />
  ) : (
    <DesktopMenu
      navbar={ navbar }
      location={ location }
    />
  ) : <MenuPlaceholder/>;
};

export default Navigation;
