/* eslint-disable no-undef */

const local = {
  url: 'http://localhost:8000/',
  apiUrl: 'https://dev.api.profil-software.com/api/',
  mediaUrl: 'https://dev.api.profil-software.com',
  allowAnalytics: false,
};

const dev = {
  url: 'https://dev.profil-software.com/',
  apiUrl: 'https://dev.api.profil-software.com/api/',
  mediaUrl: 'https://dev.api.profil-software.com',
  allowAnalytics: false,
};

const prod = {
  url: 'https://profil-software.com/',
  apiUrl: 'https://api.profil-software.com/api/',
  mediaUrl: 'https://api.profil-software.com',
  allowAnalytics: true,
};

const env = {
  pipedriveCompany: process.env.GATSBY_PIPEDRIVE_COMPANY,
  pipedrivePlaybook: process.env.GATSBY_PIPEDRIVE_PLAYBOOK,
  googleAnalytics: process.env.GATSBY_GOOGLE_ANALYTICS,
  googleRemarketing: process.env.GATSBY_GOOGLE_REMARKETING,
  googleTagmanager: process.env.GATSBY_GOOGLE_TAGMANAGER,
  facebookPixel: process.env.GATSBY_FACEBOOK_PIXEL,
  disqus: process.env.GATSBY_DISQUS_SHORTNAME,
};

const config = process.env.GATSBY_APP_STAGE === 'prod' ?
              prod : process.env.GATSBY_APP_STAGE === 'dev' ?
              dev : local;

module.exports = { ...config, ...env };
