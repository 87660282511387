import React from 'react';
import loadable from '@loadable/component';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const Newsletter = loadable(() => import('/src/components/Layout/Newsletter/Newsletter'));

const COOKIE_NAME = 'hide_newsletter';

const NewsletterWrapper = ({ data }) => {
  const isNewsletterVisible = useSelector((state) => state.isNewsletterVisible);
  const [ cookies, setCookie ] = useCookies([ COOKIE_NAME ]);

  const setNewsletterCookie = (expirationDate) => {
    setCookie(COOKIE_NAME, true, { expires: expirationDate } );
  };

  return (!cookies[ COOKIE_NAME ] || isNewsletterVisible) ? (
    <Newsletter data={ data } setNewsletterCookie={ setNewsletterCookie } newsletterCookie={ cookies[ COOKIE_NAME ] }/>
    ) : null;
};

export default NewsletterWrapper;
